import React from 'react'
import { Link } from 'gatsby'

import Layout from '../layouts/home'
import SEO from '../components/seo'
import RegisterForm from '../components/registerForm'

export default function AboutPage() {

  return <Layout>
    <SEO
      title="About"
      keywords={[]}
      description="Leading online portal in Nepal helping users to find the right wedding vendors, event services and resources"
      ogTitle="About - CeremonyNepal"
   />
    <section class="reserve-block light-bg">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <h5>About Us</h5>
          </div>
        </div>
      </div>
    </section>
    <section class="reserve-block">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <p>
              With a mission to help our users find the best vendor and service providers in the town, we aim to provide a highly responsive platform to browse the best wedding and event vendors, photographers, boutiques, makeup artists, event planners and more with photos, reviews and contact information at the click of a button.
            </p>
            <p>
              Weddings and Events are stressful and we hope CeremonyNepal will help users to navigate through the plethora of options and gather a team of vendors and service provider to make their moments memorable, in their budget and style.
            </p>
            <Link to="/" class="btn btn-danger">Start Browsing</Link>
            <p/>
            <p>
              To vendors and service provider, listing your company on CeremonyNepal gives your brand the online exposure and search engine optimizations  it needs. Listing is and always be free. Display your portfolio, contacts, packages, reviews, maps everything in one place. Connect with the right customers and grow your business.
            </p>
            <p>
              We set out to create Nepal’s most targeted, up-to-date, and comprehensive special events and weddings guide. We pride ourselves on working extra close with vendors like you toward a shared goal of success.
            </p>
              <RegisterForm alignBtn="text-left" />
          </div>
        </div>
      </div>
    </section>
  </Layout>
}

